import React from "react"
import { Helmet } from "react-helmet"
import styles from "./index.module.css"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Layout from '../components/layout'
import Container from '../components/container'
import Segments from '../components/segments'

class Gallery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      photos: [
        '/img/voga1.jpg',
        '/img/fortaleza-06.jpg',
        '/img/fortaleza-08.jpg',
        '/img/voga2.jpg'
      ]
    }
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 6000,
      speed: 1000,
      lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      arrows: false,
      pauseOnHover: false
    };
    return (
      <Slider {...settings}>
        {this.state.photos.map((photo, index) => {
          return (
            <div key={index}>
              <img src={photo} alt="" style={{ width: '100%' }} />
            </div>
          )
        })}
      </Slider>
    );
  }
}

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={`Somos uma empresa plural.${' '}
            Juntamos todas as frentes, arquitetura, design de interiores, móveis,${' '}
            incorporações, feiras e eventos para entregar soluções completas para espaços diversos.`}
          />
          <meta property="og:description" content={`Somos uma empresa plural.${' '}
            Juntamos todas as frentes, arquitetura, design de interiores, móveis,${' '}
            incorporações, feiras e eventos para entregar soluções completas para espaços diversos.`}
          />
          <meta name="image" content='/img/voga1.jpg' />
          <meta property="og:image" content='/img/voga1.jpg' />
          <title>Grupo Vinte</title>
          <link rel="canonical" href="https://grupovinte.com.br/" />
          <html lang="pt-br" />
        </Helmet>
        <section className={styles.gallery}>
          <Gallery />
        </section>
        <Container>
          <Segments />
        </Container>
      </Layout>
    )
  }
}

export default Index
