import React from 'react'
import { Link } from "gatsby"

import styles from './segments.module.css'

export default () => (
  <div className={styles.segments}>
    <Link to="/feiras-e-eventos/">
      <div className={styles.wrapper}>
        <img src="/img/logo-eventos.png" alt="Eventos" />
        <span className={styles.segment}>Especialistas em montagem de stand, feiras, eventos e cenografia.</span>
      </div>
    </Link>
    <Link to="/marcenaria/">
      <div className={styles.wrapper}>
        <img src="/img/logo-marcenaria.png" alt="Marcenaria" />
        <span className={styles.segment}>Móveis sob medida de alto padrão.<br/>Soluções inteligentes</span>
      </div>
    </Link>
  </div>
)
